<template>
  <div id="app">
    <section class="section pt-0 pb-0">
      <div class="container">
        <h1 class="is-size-2 has-text-centered">
          <i class="fa-solid fa-cubes"></i>
          Costchain
        </h1>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <BalanceView
            :contractStorage="contractStorage"
            @incrementLoading="loading++"
            @decrementLoading="loading--"
        />
      </div>
    </section>
    <section class="section" v-if="showSetBalanceRequests">
      <div class="container">
        <SetBalanceRequestView
            :contractStorage="contractStorage"
            @setBalance="params => executeContractMethod(setBalance, params)"
            @approveSetBalance="params => executeContractMethod(approveSetBalance, params)"
            @denySetBalance="params => executeContractMethod(denySetBalance, params)"
            @incrementLoading="loading++"
            @decrementLoading="loading--"
        />
      </div>
    </section>
    <section class="section">
      <div class="container">
        <h2 class="is-size-4 has-text-centered pb-5">
          <i class="fa-solid fa-file-contract"></i>
          &nbsp;Actions
        </h2>
        <div class="columns is-multiline">
          <div class="column is-one-quarter-desktop is-full">
            <TransferTokens @submit="params => executeContractMethod(transfer, params)"/>
          </div>
          <div class="column is-one-quarter-desktop is-full">
            <MintTokens @submit="params => executeContractMethod(mint, params)"/>
          </div>
          <div class="column is-one-quarter-desktop is-full">
            <BurnTokens @submit="params => executeContractMethod(burn, params)"/>
          </div>
          <div class="column is-one-quarter-desktop is-full">
            <RequestSetBalance @submit="params => executeContractMethod(requestSetBalance, params)"/>
          </div>
        </div>
      </div>
    </section>
    <section class="section pt-0">
      <div class="container">
        <a :href="`${blockExplorerBaseUrl}/${contractAddress}`" target="_blank" rel="noopener">
          View on-chain data
          <sup><i class="fa-solid fa-arrow-up-right-from-square"></i></sup>
        </a>
        <p class="is-pulled-right has-text-grey has-text-weight-light">Powered by &#42793; Tezos</p>
      </div>
    </section>
    <LoadingState v-if="loading > 0"/>
  </div>
</template>

<script>
import { TezosToolkit, MichelCodecPacker } from '@taquito/taquito';
import { BeaconWallet } from '@taquito/beacon-wallet';
import { toast } from 'bulma-toast';
import TransferTokens from './components/actions/TransferTokens';
import MintTokens from './components/actions/MintTokens';
import BurnTokens from './components/actions/BurnTokens';
import RequestSetBalance from './components/actions/RequestSetBalance';
import BalanceView from './components/BalanceView';
import SetBalanceRequestView from './components/SetBalanceRequestView';
import LoadingState from './components/LoadingState';

const ENV = window.location.href.indexOf("localhost") !== -1
  ?
    {
      network: 'hangzhounet',
      contractAddress: 'KT18txUURUA8tjfJU1YnVakLuhSkzTcARRsH',
      rpcUrl: 'https://hangzhounet.api.tez.ie',
      blockExplorerBaseUrl: 'https://hangzhou2net.tzkt.io'
    }
  :
    {
      network: 'mainnet',
      contractAddress: 'KT1A33HFimEMkdByzYo352fsNe5LuT1XzYVi',
      rpcUrl: 'https://mainnet.api.tez.ie',
      blockExplorerBaseUrl: 'https://tzkt.io'
    };

export default {
  name: 'App',
  components: {
    SetBalanceRequestView,
    BalanceView,
    RequestSetBalance,
    BurnTokens,
    MintTokens,
    TransferTokens,
    LoadingState
  },
  computed: {
    contractAddress() {
      return ENV.contractAddress;
    },
    blockExplorerBaseUrl() {
      return ENV.blockExplorerBaseUrl;
    },
    showSetBalanceRequests() {
      if (!this.contractStorage) {
        return false;
      }
      return this.contractStorage.setBalanceRequests.size > 0;
    }
  },
  data() {
    return {
      Tezos: new TezosToolkit(ENV.rpcUrl),
      wallet: new BeaconWallet({
        name: 'CostChain',
        iconUrl: 'https://costchain.roelink.eu/static/icon.png',
        preferredNetwork: ENV.network
      }),
      walletConnected: false,
      contract: null,
      contractStorage: null,
      loading: 0
    }
  },
  methods: {
    async fetchContract() {
      this.loading++;
      this.contract = await this.Tezos.wallet.at(this.contractAddress);
      this.contractStorage = await this.contract.storage();
      this.loading--;
    },
    async connectWallet() {
      await this.wallet.requestPermissions({
        network: {
          type: ENV.network
        }
      });
      this.Tezos.setWalletProvider(this.wallet);
      this.walletConnected = true;
    },
    async executeContractMethod(method, params) {
      try {
        if (!this.walletConnected) {
          await this.connectWallet();
        }
        const op = await method(params);
        this.loading++;
        await op.confirmation(1);
        await this.fetchContract();
        this.loading--;
      } catch (err) {
        toast({
          message: `An error occurred: ${err.message}`,
          type: 'is-danger',
          duration: 3000,
          dismissible: true,
          animate: { in: 'fadeIn', out: 'fadeOut' }
        });
      }
    },
    async transfer({ address, amount, message }) {
      const walletAddress = await this.wallet.getPKH();
      if (message !== null) {
        return this.contract.methods.transferWithMessage(walletAddress, address, amount, message).send();
      }
      return this.contract.methods.transfer(walletAddress, address, amount).send();
    },
    async mint({ amount }) {
      const walletAddress = await this.wallet.getPKH();
      return this.contract.methods.mint(walletAddress, amount).send();
    },
    async burn({ amount }) {
      const walletAddress = await this.wallet.getPKH();
      return this.contract.methods.burn(walletAddress, amount).send();
    },
    requestSetBalance({ address, amount }) {
      return this.contract.methods.requestSetBalance(address, amount).send();
    },
    setBalance({ address }) {
      return this.contract.methods.setBalance(address).send();
    },
    approveSetBalance({ address }) {
      return this.contract.methods.approveSetBalance(address).send();
    },
    denySetBalance({ address }) {
      return this.contract.methods.denySetBalance(address).send();
    }
  },
  async created() {
    this.Tezos.setPackerProvider(new MichelCodecPacker());
    await this.fetchContract();
  }
}
</script>

<style lang="scss">
@import 'assets/scss/app';

#app {
  padding-top: 2rem;
}
</style>
