<template>
  <div>
    <h2 class="is-size-4 has-text-centered pb-5">
      <i class="fa-solid fa-pen-to-square"></i>
      &nbsp;Set balance requests
    </h2>
    <div class="table-container">
      <table class="table is-fullwidth is-bordered">
        <thead>
        <tr>
          <th>Address</th>
          <th>New balance</th>
          <th>Approvals</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="request in requests" :key="request.address">
          <td>{{ request.address }}</td>
          <td>{{ request.newBalance }} JIM</td>
          <td>{{ request.approvals.join(', ') }}</td>
          <td>
            <button class="button is-primary is-fullwidth" @click="setBalance(request.address)">Confirm</button>
            <button class="button is-primary is-fullwidth mt-2" @click="approveSetBalance(request.address)">Approve</button>
            <button class="button is-primary is-fullwidth mt-2" @click="denySetBalance(request.address)">Deny</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SetBalanceRequestView',
  props: {
    contractStorage: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      requests: []
    }
  },
  watch: {
    contractStorage(val) {
      if (val) {
        this.loadRequests();
      }
    }
  },
  methods: {
    async loadRequests() {
      this.$emit('incrementLoading');
      const entries = await this.contractStorage.setBalanceRequests.entries()
      const requests = [];
      for (const entry of entries) {
        requests.push({
          address: entry[0],
          newBalance: entry[1].newBalance.toNumber() / 100,
          approvals: entry[1].approvals
        });
      }
      this.requests = requests;
      this.$emit('decrementLoading');
    },
    setBalance(address) {
      this.$emit('setBalance', {
        address
      });
    },
    approveSetBalance(address) {
      this.$emit('approveSetBalance', {
        address
      });
    },
    denySetBalance(address) {
      this.$emit('denySetBalance', {
        address
      });
    }
  },
  created() {
    if (this.contractStorage) {
      this.loadRequests();
    }
  }
}
</script>

<style scoped>

</style>
