<template>
  <div>
    <h2 class="is-size-4 has-text-centered pb-5">
      <i class="fa-solid fa-coins"></i>
      &nbsp;Balances
    </h2>
    <div class="table-container">
      <table class="table is-fullwidth is-bordered">
        <thead>
        <tr>
          <th>Balance</th>
          <th>Address</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="balance in balances" :key="balance.address">
          <td>{{ balance.balance }} JIM</td>
          <td>
            <span class="pointer" @click="copyValue(balance.address)" title="Copy">
              {{ balance.address }}
              &nbsp;<i class="fa-regular fa-copy"></i>
            </span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { toast } from 'bulma-toast';

export default {
  name: 'BalanceView',
  props: {
    contractStorage: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      balances: []
    }
  },
  watch: {
    async contractStorage(val) {
      if (val) {
        this.$emit('incrementLoading');
        const entries = await val.balances.entries();
        const balances = [];
        for (const entry of entries) {
          balances.push({
            address: entry[0],
            balance: entry[1].balance.toNumber() / 100
          });
        }
        this.balances = balances;
        this.$emit('decrementLoading');
      }
    }
  },
  methods: {
    copyValue(value) {
      const dummy = document.createElement('input');
      document.body.appendChild(dummy);
      dummy.value = value;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
      toast({
        message: 'Address copied to clipboard',
        type: 'is-primary',
        duration: 3000,
        dismissible: true,
        animate: { in: 'fadeIn', out: 'fadeOut' }
      });
    }
  }
}
</script>

<style scoped>

</style>
