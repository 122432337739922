<template>
  <form @submit.prevent="onSubmit">
    <h3 class="is-size-5 has-text-centered pb-3">Burn JIM tokens</h3>
    <div class="field">
      <label class="label">Amount</label>
      <div class="control">
        <input class="input" type="number" min="0" step="0.01" v-model="amount">
      </div>
    </div>
    <div class="field">
      <div class="control">
        <button value="submit" class="button is-primary is-fullwidth" :disabled="buttonDisabled">Burn</button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: 'BurnTokens',
  computed: {
    buttonDisabled() {
      return this.amount === null || this.amount <= 0;
    }
  },
  data() {
    return {
      amount: null
    }
  },
  methods: {
    onSubmit() {
      this.$emit('submit', {
        amount: Math.round(this.amount * 100)
      });
      this.amount = null;
    }
  }
}
</script>

<style scoped>

</style>
