<template>
  <form @submit.prevent="onSubmit">
    <h3 class="is-size-5 has-text-centered pb-3">Transfer JIM tokens</h3>
    <div class="field">
      <label class="label">To address</label>
      <div class="control">
        <input class="input" type="text" v-model="address">
      </div>
    </div>
    <div class="field">
      <label class="label">Amount</label>
      <div class="add-on">
        <div class="control">
          <input class="input" type="number" min="0" step="0.01" v-model="amount">
        </div>
        <div class="control">
          <div class="select">
            <select v-model="selectedDivider">
              <option v-for="i in 5" :key="i" :value="i">/{{ i }}</option>
            </select>
          </div>
        </div>
      </div>
      <p class="help" v-if="amount">{{ dividedAmount }} JIM</p>
    </div>
    <div class="field">
      <label class="label">Message (optional)</label>
      <div class="control">
        <input class="input" type="text" v-model="message">
      </div>
    </div>
    <div class="field">
      <div class="control">
        <button value="submit" class="button is-primary is-fullwidth" :disabled="buttonDisabled">Transfer</button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: 'TransferTokens',
  computed: {
    dividedAmount() {
      return Math.round(this.amount / this.selectedDivider * 100) / 100;
    },
    buttonDisabled() {
      return !this.address || this.amount === null || this.amount <= 0;
    }
  },
  data() {
    return {
      address: '',
      amount: null,
      selectedDivider: 1,
      message: ''
    }
  },
  methods: {
    onSubmit() {
      this.$emit('submit', {
        address: this.address,
        amount: Math.round(this.dividedAmount * 100),
        message: this.message || null
      });
      this.address = '';
      this.amount = null;
      this.selectedDivider = 1;
      this.message = '';
    }
  }
}
</script>

<style lang="scss" scoped>
.add-on {
  display: flex;
  justify-content: flex-start;

  .control:first-child {
    flex: 1;
    margin-right: -1px;
  }

  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:hover {
      z-index: 2;
    }

    &:focus, &:active {
      z-index: 3;

      &:hover {
        z-index: 4;
      }
    }
  }

  select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &:hover {
      z-index: 2;
    }

    &:focus, &:active {
      z-index: 3;

      &:hover {
        z-index: 4;
      }
    }
  }
}
</style>
